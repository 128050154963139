import React from "react";
import Logo from "../../static/images/RJlogo.png";
import LogoOne from "../../static/images/rjlogoone.png";
import { Link } from "gatsby";

export default function navbar() {
  return (
    <div
      className="bestnav mx-auto w-full  md:px-2 sticky top-0  z-50 shadow-md "
      style={{ backgroundColor: "#ffffff" }}
    >
      <section class="overflow" style={{ fontFamily: "poppins" }}>
        <div class="flex items-center justify-between ml-10 lg:px-5 lg:py-5 bg-white">
          <div class=" hidden md:block w-auto">
            <div class="flex flex-wrap items-center">
              <div class=" w-48 mr-14">
                <a href="/">
                  <img src={LogoOne} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div class="w-auto">
            <div class="flex flex-wrap items-center">
              <div class="w-auto hidden lg:block">
                <ul class="flex items-center mr-10">
                  <li class=" font-semibold mr-9 text-indigo-800 hover:text-sky-600 text-md">
                    <a href="/">Home</a>
                  </li>
                  <li class="font-semibold mr-9 text-indigo-800 hover:text-sky-600 text-md">
                    <a href="/about">About Us</a>
                  </li>
                  <li class="font-semibold mr-9 text-indigo-800 hover:text-sky-600 text-md">
                    <a href="/founder">Founder’s Profile</a>
                  </li>

                  {/* desktop code */}

                  <div className="dropdown dropdown-hover">
                    <label
                      tabIndex={1}
                      className=" font-semibold mr-9 text-indigo-900  hover:text-sky-600 text-md"
                    >
                      <a href="/services">Services</a>
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu p-2 shadow bg-white text-indigo-900 font-semibold rounded-box w-80"
                    >
                      <li>
                        <a href="/ActuarialConsulting">
                          Actuarial Consulting and Valuation
                        </a>
                      </li>
                      <li>
                        <a href="/EmployeeBenefits">
                          Employee Benefit Valuation and Structuring
                        </a>
                      </li>
                      <li>
                        <a href="/BusinessConsulting">
                          Enterprise Risk Management (ERM) and ORSA
                        </a>
                      </li>
                      {/* <li>
                        <a href="/TechnologySolutions">Investment Consulting</a>
                      </li> */}
                      <li>
                        <a href="/DataMining">Technology Solutions</a>
                      </li>
                      <li>
                        <a href="/MachineLearningAI">
                          Business and Management Consulting
                        </a>
                      </li>
                      <li>
                        <a href="/Dataminingandengineering">
                          Data Analytics and Management Dashboarding
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="dropdown dropdown-hover">
  <label tabIndex={1} className=" font-semibold mr-9 text-indigo-900  hover:text-sky-600 text-lg"><a href="/product">Products</a></label>
  <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-white text-indigo-900 font-semibold rounded-box w-80">
    <li><a href="/UnderwritingScoreModel">Underwriting Score Model</a></li>
    <li><a href="/i-IFRS17">i-IFRS17</a></li>
    <li><a href="/CSMEngine">CSM Engine</a></li>
    <li><a href="/UnLimit">UnLimit</a></li> 
  
  
  </ul>
</div> */}

                  <li class="font-semibold mr-9 text-indigo-900 hover:text-sky-600 text-md">
                    <a href="/blogs">Blogs</a>
                  </li>

                  <li class="font-semibold mr-9 text-indigo-900 hover:text-sky-600 text-md">
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>

              {/* ------------------------------------------------------------------------------------------------- */}
              {/* mobile code */}

              <div class="w-auto lg:hidden mr-3">
                <div className="relative flex items-center md:justify-between justify-around">
                  <div className="navbar">
                    <div className="navbar-start  ">
                      <div className="dropdown dropdown-start lg:hidden w-full">
                        <label tabindex="0" className=" m-1">
                          <svg
                            class="navbar-burger text-blue-900"
                            width="60"
                            height="60"
                            viewbox="0 0 56 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="56"
                              height="56"
                              rx="28"
                              fill="currentColor"
                            ></rect>
                            <path
                              d="M37 32H19M37 24H19"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                        </label>
                        <ul
                          tabindex="0"
                          className="dropdown-content menu p-2 shadow rounded-box w-52 bg-blue-900"
                        >
                          <li>
                            <Link to="/" className=" text-white text-md">
                              Home
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/about"
                              className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                            >
                              About Us
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/services"
                              aria-label=""
                              className="inline-flex items-center text-md text-white"
                            >
                              Services
                              <svg
                                className="inline-block w-3 ml-2"
                                fill="currentColor"
                                viewBox="0 0 12 12"
                              >
                                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                              </svg>
                            </Link>

                            <ul className="bestnav menu-compact -ml-20 z-50 p-4 bg-blue-900 w-64">
                              <li>
                                <Link
                                  to="/ActuarialConsulting"
                                  className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Actuarial Valuation
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/EmployeeBenefits"
                                  className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Employee Benefits Consulting
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/BusinessConsulting"
                                  className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Enterprise Risk Management
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/DataMining"
                                  className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Data Mining & Engineering
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/MachineLearningAI"
                                  className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Machine Learning & AI
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link
                              to="/product"
                              aria-label=""
                              className="inline-flex  text-md items-center text-white"
                            >
                              Products
                              <svg
                                className="inline-block w-3 ml-2"
                                fill="currentColor"
                                viewBox="0 0 12 12"
                              >
                                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                              </svg>
                            </Link>

                            <ul className="bestnav menu-compact -ml-20 z-50 p-4 bg-blue-900 w-64">
                              <li>
                                <Link
                                  to="/UnderwritingScoreModel"
                                  className=" text-white text-lg hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  Underwriting Score Model
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/i-IFRS17"
                                  className=" text-white text-lg hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  i-IFRS17
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/CSMEngine"
                                  className=" text-white text-lg hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  CSM Engine
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/UnLimit"
                                  className=" text-white text-lg hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                                >
                                  UnLimit
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link
                              to="/blogs"
                              className=" text-white text-md hover:text-[#1BB0FF] hover:translate-x-1 delay-50 duration-100"
                            >
                              Blogs
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/contact"
                              className=" text-md text-white "
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hidden">
                <div class="flex flex-wrap items-end">
                  <div class=" w-48 mr-2">
                    <a href="/">
                      <img src={LogoOne} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
